<template>
  <div class="flex-column">
    <sticky :className="'sub-navbar'">
      <div class="filter-container">
        <base-search :searchList="$cbglDscjSearch" :listQuery='listQuery' v-on:search='handleFilter' v-on:btn-event="onBtnClicked" style="display: inline-block;"></base-search>
      </div>
    </sticky>
    <div class="app-container flex-item">
      <!-- 拖拽div -->
      <div class="dragBox" ref="dragBox">
        <div class="dragBox_left">
          <!--左侧div内容-->
          <el-card shadow="never" class="body-small" style="height: 100%;overflow:auto;">
            <basic-tree :isShowCheck="true" @handleNodeClick="handleNodeClick" @nodeClickOne="nodeClickOne" @getCheckedKeys="getCheckedKeys"></basic-tree >
          </el-card>
        </div>
        <div class="dragBox_resize" title="收缩侧边栏">
          ⋮
        </div>
        <div class="dragBox_mid">
          <!--右侧div内容-->
          <div class="bg-white" style="height: 100%;" id="table_box_height">
            <div v-if="isFirstIn" style="margin: 20% 0 0 40%;color: #aaa;">请选择左侧区域查询数据</div>
            <basic-table v-else :tableHeight="tableHeight" :tableHeaderList="firstHeaderList" :tableData="tableData" :listQuery="listQuery" @pagination="handleCurrentChange" @tableRowClick="tableRowClick" :tableTotal="tableTotal" :sepcialExchange="true" :hasOperation="true" @handleClickOperation="handleClickOperation"></basic-table >
          </div>
        </div>
      </div>
      <!-- 拖拽div -->
      <!-- <el-row style="height: 100%;" :gutter="8" >
        <el-col :span="4" style="height: 100%;">
          <el-card shadow="never" class="body-small" style="height: 100%;overflow:auto;">
            <basic-tree :Treedata="orgsTree" :isShowCheck="true" @handleNodeClick="handleNodeClick" @getCheckedKeys="getCheckedKeys"></basic-tree >
          </el-card>
        </el-col>
        <el-col :span="20" style="height: 100%;">
          <div class="bg-white" style="height: 100%;" id="table_box_height">
            <basic-table :tableHeight="tableHeight" :tableHeaderList="firstHeaderList" :tableData="tableData" :listQuery="listQuery" @pagination="handleCurrentChange" @tableRowClick="tableRowClick" :tableTotal="tableTotal" :sepcialExchange="true" :hasOperation="true" @handleClickOperation="handleClickOperation"></basic-table >
          </div>
        </el-col>
      </el-row> -->
    </div>


    <!-- 添加弹窗 -->
    <general-form :FormDialog="FormDialog" :FormList="FormList" :title="addTitle" :FormData="addform"  @cancelDialog="cancelDialog" @confirmDialog="confirmDialog"></general-form>

    <!-- 绑定定时任务 -->
    <general-form :FormDialog="bandDialog" :FormList="bandFormList" :title="'绑定定时任务'" :FormData="bandForm"  @cancelDialog="cancelDialogBand" @confirmDialog="confirmDialogBand" :isSpanAll="true"></general-form>


    <el-dialog  class="dialog-mini user-dialog" :title="detailsTitle" :visible.sync="detailsDialog" width="30%">
      <span v-for="(i,index) in detailsList" class="detailsSpan">{{i.jobName}}</span>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="detailsDialog = false">取 消</el-button> -->
        <el-button type="primary" @click="detailsDialog = false">确 定</el-button>
      </span>
    </el-dialog>

  </div>

</template>

<script>    
  import generalForm from '@/components/generalForm'//通用添加弹窗组件
  import * as basicDataApi from '@/api/basicDataApi/basicDataApi'//基础资料接口

  
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import waves from '@/directive/waves' // 水波纹指令
  import Sticky from '@/components/Sticky'
  import elDragDialog from '@/directive/el-dragDialog'
  export default {
    name: 'hdybqygl',
    components: {
      Sticky,
      generalForm
    },
    directives: {
      waves,
      elDragDialog
    },
    data() {
      return {
        isFirstIn:true,//首次进入页面
        listQuery: { // 查询条件
          page: 1,
          limit: 50,
          TreeIds:[],
          key: '',
        },
        orgs: [], // 用户可访问到的组织列表
        tableData:[],
        tableHeight:500,//表格高度
        CheckedKey:[],//选中数据的id集合
        tableChooseRow:{},//table选中数据
        FormDialog:false,//添加弹窗
        addform:{
          timingScheme:'',
          concentratorAddress:'',
          collectorAddress:'',
          surfaceAddress:'',
        },
        FormList:{//表格列
          column:[
            {columnKey:'timingScheme',columnDescription:'定时方案',columnType:'text',prop:'timingScheme',width:'120',placeholder:"定时方案",},
            {columnKey:'concentratorAddress',columnDescription:'集中器地址',columnType:'text',prop:'concentratorAddress',width:'120',placeholder:"集中器地址",},
            {columnKey:'collectorAddress',columnDescription:'采集器地址',columnType:'text',prop:'collectorAddress',width:'120',placeholder:"采集器地址",},
            {columnKey:'surfaceAddress',columnDescription:'表地址',columnType:'text',prop:'surfaceAddress',width:'120',placeholder:"表地址",},
          ],
          //校验
          rule:{
            timingScheme: [{ required: true, message: "定时方案不能为空", trigger: "blur" }, ],
          }
        },
        addTitle:'添加',//添加弹窗标题
        firstHeaderList:[],// 主列表列定义
        tableTotal:0,//明细条数
        bandDialog:false,//绑定定时任务
        bandFormList:{//绑定定时任务列
          column:[
            {
              columnKey:'taskIds',
              columnDescription:'定时任务',
              columnType:'selectTimeTask',
              placeholder:"请选择定时任务",
              prop:'taskIds',
              width:'120',
              multiple:true
            },
          ],
          //校验
          rule:{
            taskId: [{ required: true, message: "定时任务不能为空", trigger: "blur" }, ],
          }
        },
        bandForm:{//绑定定时任务from
          meterId:'',
          taskIds:'',
        },
        detailsTitle:'',//弹窗标题
        detailsDialog:false,
        detailsList:[],//绑定任务列表
      }
    },
    watch: {

    },
    computed: {
      
    },
    filters: {
      
    },
    created() {
      
    },
    mounted() {
      // this.getList()
      // 计算table高度
      this.$nextTick(()=>{
        let ele=document.getElementById("table_box_height");
        this.tableHeight=ele.offsetHeight-50
        window.addEventListener('resize', () => { 
          let ele=document.getElementById("table_box_height");
          this.tableHeight=ele.offsetHeight-50
          this.$forceUpdate()
        });
        // 计算table高度
      })

      this.$basicFun.dragControllerDiv();//拖拽div方法
    },
    methods: {
      // 节点点击
      handleNodeClick(data) {
        this.listQuery.TreeIds = data
      },
      // 添加节点选中数据
      getCheckedKeys(data){
        console.log(data)
        this.CheckedKey = data
        var s = this.CheckedKey.length
        this.tableData = this.$store.state.tableDataSearch.slice(0,s)
      },
      //节点点击
      nodeClickOne(data){
        this.listQuery.TreeIds = []
        this.listQuery.TreeIds.push(data.id)
        this.getList()
      },
      // 按钮点击
      onBtnClicked: function(domId) {
        console.log('you click:' + domId)
        switch (domId) {
          case 'btnDel'://删除
            this.handleDelete(this.tableChooseRow)
            break
          case 'btnAdd'://添加
            this.FormDialog = true
            break
          case 'btnBand'://绑定定时任务
            if(this.tableChooseRow.meterId){
              this.bandTask()//绑定定时任务
            }else{
              this.$message.error('请选择一个设备进行操作！');
            }
            break
          default:
            break
        }
      },
      // 获取数据
      getList() {
        basicDataApi.dscjGetList(this.listQuery).then(response => {
          if(response.code == 200){
            response.columnHeaders.forEach((item) => { // 首字母小写
              item.key = item.key.substring(0, 1).toLowerCase() + item.key.substring(1)
            })
            this.firstHeaderList = response.columnHeaders.filter(u => u.browsable && u.isList && u.key != 'jobState')
            
            this.tableData = response.data
            this.tableTotal = response.count
            this.isFirstIn = false
          }else{
            this.firstHeaderList = []
            this.tableData = []
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
        }).catch(()=>{
          this.firstHeaderList = []
          this.tableData = []
        })
      },
      // 搜索
      handleFilter() {
        this.listQuery.page = 1
        this.getList()
      },
      // 切换分页
      handleCurrentChange(val) {
        this.tableChooseRow = {}//重置选中项
        this.listQuery.page = val.page
        this.listQuery.limit = val.limit
        this.getList()
      },
      // 行点击
      tableRowClick(row){
        this.tableChooseRow = row
      },
      handleDelete(row) { // 多行删除
        this.tableData.splice(this.tableData.findIndex(e=>e.serialNumber === row.serialNumber),1)
      },
      // add弹窗取消
      cancelDialog(val){
        this.FormDialog = val
      },
      // add弹窗提交
      confirmDialog(){
        this.$store.state.tableDataSearch.push(this.addform)
        this.addform={
          timingScheme:'',
          concentratorAddress:'',
          collectorAddress:'',
          surfaceAddress:'',
        }
        this.FormDialog = false
      },
      // 绑定定时任务弹窗取消
      cancelDialogBand(val){
        this.bandDialog = val
      },
      // 绑定定时任务弹窗提交
      confirmDialogBand(){
        this.bandForm.meterId=this.tableChooseRow.meterId
        basicDataApi.dscjBand(this.bandForm).then(response => {
          if(response.code == 200){
            this.$notify({
              message: response.message,
              type: 'success',
              duration: 2000
            });
            this.bandDialog = false
            this.getList()
          }else{
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
        })
      },
      // 点击操作查看
      handleClickOperation(val){
        basicDataApi.dscjDetails({surfaceNum:val.surfaceNum}).then(response => {
          if(response.code == 200){
            this.detailsTitle = '编号' + val.surfaceNum + '的设备所绑定的定时任务：'
            this.detailsList = response.result
            this.detailsDialog = true
          }else{
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
        })
      },
      //绑定定时任务
      bandTask(){
        this.bandForm.taskIds = []
        basicDataApi.dscjDetails({surfaceNum:this.tableChooseRow.surfaceNum}).then(response => {
          if(response.code == 200){
            response.result.forEach(i=>{
              this.bandForm.taskIds.push(i.id)
            })
            this.bandDialog = true
          }else{
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
        })
      }
    },
  }

</script>

<style lang="scss" scoped>
  .detailsSpan{
    display: inline-block;
    margin: 20px 10px;
    box-sizing: border-box;
    border-color: transparent;
    margin: 2px 0 2px 6px;
    background-color: #f0f2f5;
    padding: 5px 10px;
  }
</style>
